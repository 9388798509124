<template>
    <div class="letsgo-container" :style="{'height': __layoutCenterHeightCalculate.value - 20 +'px'}">
        <!-- component Let's go: experiences -->
        <components-let-s-go
            :header-title="response.destinationName"
            :title="$tc('adventure.experience.main', 1)"
            :busy-on="busyOn"
            :button-next-hidden="true"
            :show-no-hits-message="showNoHitsMessage"
            @eventPrevious="eventPrevious"
        >
            <!-- content: destinations -->
            <template #content>
                <div v-for="experience in response.experiences">
                    <b-button
                        class="mb-2 px-2"
                        style="max-width: 320px; width: 100%; height: 80px;"
                        @click="__gotoExperience(experience, ['story', 'mission', 'tasks'])"
                    >
                        <div class="d-flex justify-content-around align-items-center">
                            <span style="width: 100%">{{experience.name}}</span>
                            <b-icon-patch-check-fill
                                v-if="experience.solutions.percent !== null && experience.solutions.percent === 100"
                                class="mr-2"
                                variant="white"
                                scale="2"
                            />
                            <span v-else-if="experience.solutions.percent !== null" style="width: 3rem" class="border px-1">{{experience.solutions.percent}}%</span>
                        </div>
                    </b-button>
                </div>
            </template>
            <!-- content: help -->
            <template #help>
                <div v-html="$t('adventure.help_content.experiences')"></div>
                <!-- solved -->
                <div class="mt-2">
                    {{$t('adventure.help_content.experience_solved')}}
                    <b-icon-patch-check-fill variant="white" scale="1.8" class="mx-2 border-secondary"/>.
                </div>
            </template>
        </components-let-s-go>
    </div>
</template>

<script>
import ComponentsLetSGo from "@/components/_public/let-s-go/elements/ComponentLet-s-go";

export default {
    name: "Let-s-goExperiences",
    components: {ComponentsLetSGo},
    props: {},
    data() {
        return {
            busyOn: false,
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                getExperiences: `api/v1/let-s-go/experiences`,
            },
            showNoHitsMessage: false,
            response: [],
        }
    },
    mounted() {
        this.loadDataFromBase();
    },
    methods: {
        // <-- events -->
        eventPrevious() {
            this.__gotoDestinations(this.$route.params.route);
        },
        // <-- base -->
        /**
         * @vuese
         * Load destinations from database
         */
        loadDataFromBase() {
            this.busyOn = true;
            this.showNoHitsMessage = false;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getExperiences + `/${this.$route.params.route}` + `/${this.$route.params.destination}`, config)
                .then((response) => {
                    if (this.__isNull(response.data.experiences)) {
                        this.showNoHitsMessage = true;
                        this.busyOn = false;
                        return;
                    }
                    this.$set(this, 'response', response.data);
                    this.busyOn = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
